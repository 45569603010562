import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Parallax } from 'react-parallax';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import ReactCompareImage from 'react-compare-image';
import Container from 'react-bootstrap/esm/Container';

import before1 from './images/before1.webp'
import after1 from './images/after1.webp'
import before2 from './images/before2.webp'
import after2 from './images/after2.webp'
import before3 from './images/before3.webp'
import after3 from './images/after3.webp'
import method from './images/logo/curly-girl-method-icon.svg'
function Home() {

    return (
        <>
            <Container fluid id="home">

                <Parallax
                    bgImage={require('./images/achtergrond1.webp')}
                    bgImageAlt="krullen"
                    strength={300}
                >
                    <div className="soirree text-center" style={{ height: '400px' }} >
                        <h1 >Coiffeur Soirée</h1>
                        <h2 >Curl Specialist</h2>
                        <Button href="https://salonkee.be/salon/coiffeur-soiree?lang=nl&modal=true" className="btn-camel" target='_blank'> Boek nu</Button></div>

                </Parallax>
            </Container>
            <Container className="mt-5">
                <Row lg="1" className="text-center" >
                    <Col className="">
                        <h1 className="pb-3">Hey Curl Queen,</h1>
                        <p className="text-center">Heb jij na jarenlang zoeken nog altijd geen kapper gevonden die goed kan omgaan met je krullen? Dringt je kapper aan om je krullen uit te brushen? Heb je de neiging om je haar opnieuw te wassen eens je terug thuis bent?
                            Ben je het gezaag van je kapper beu over hoe "moeilijk en onhandelbaar" je krullen zijn? Zijn je krullen nog altijd te droog en pluizig na tal van mislukte pogingen met verschillende
                            producten?</p>
                        <p>Zoek dan niet verder!</p>
                        <p>Bij Coiffeur Soirée ben je aan het juiste adres voor het perfecte krullend kapsel. Bij ons worden je krullen op droog haar geknipt, dit is overigens de enige en juiste manier om krullen te knippen. Het voordeel van knippen op droog haar is dat het natuurlijke krulpatroon hierbij goed zichtbaar is.
                            We houden rekening met je soort krultype en hierop passen we verschillende kniptechnieken toe. Tot slot werken wij enkel en alleen met natuurlijke producten volgens de Curly Girl Method, om niet
                            enkel en alleen je krullen er mooi te laten uitzien maar ook om je krullend haar opnieuw gezond te maken in combinatie met de juiste technieken en producten (CG approved).</p>

                        <p>Waar wacht je nog op?<br />
                        Boek vandaag nog je afspraak bij Coiffeur Soirée en wij zorgen gegarandeerd voor een stralende glimlach op jouw gezicht!
                         </p>
                        <p>Als gevolg van medische redenen kunnen alleen dames en meisjes met haar vanaf de oren momenteel diensten boeken.
                             Helaas kan ik geen korte coupes (boven de oren), mannen en jongens behandelen vanwege mogelijke schouderblessures.
                              Ik verwijs jullie hiervoor graag door naar mijn collega 
                              Aristidis, die jullie met plezier zal ontvangen in Kapper The Duke op Begijnengracht 85 in Gent.</p>
<br/>
<p>Onze expertise ligt bij het stylen van golvend en krullend haar voor dames. Voor kinderen en dames met afro-,
     kroes- of gemengd haar verwijs ik jullie graag door naar mijn collega Shanice hairdresser. 
     Zij is gespecialiseerd in het behandelen van afro-, kroes- en gemengd haar met de juiste adviezen en producten. Haar salon is gelegen op Dampoortstraat 91,
      9000 Gent en ze staat klaar om jouw haar perfect te stylen!<br/></p>
                    </Col>


                    <img src={method} height="200" alt="logo" />

                </Row >
            </Container>


            <Container className="mt-5">
                <h3>Before & After</h3>
                <Row className="m-5" lg={3} md={2} sm={2} xs={1}>
                    <Col className="m-2 mx-auto" >
                        <ReactCompareImage leftImage={before1} rightImage={after1} leftImageAlt="before" rightImageAlt="after" />
                    </Col>
                    <Col className="m-2 mx-auto">
                        <ReactCompareImage leftImage={before2} rightImage={after2} leftImageAlt="before" rightImageAlt="after" />
                    </Col>
                    <Col className="m-2 mx-auto">
                        <ReactCompareImage leftImage={before3} rightImage={after3} leftImageAlt="before" rightImageAlt="after" />
                    </Col>

                </Row>
            </Container>
            <Container fluid className="bg-light">

            </Container>


        </>
    );
}

export default Home;
