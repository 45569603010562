import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import mood from './images/my-mood.png';
import { Parallax } from 'react-parallax';

function OverMij() {

  return (
    <>
      <Container fluid className="mt-5 bg-light">
        <Parallax
          bgImage={require('./images/achtergrond_3.webp')}
          bgImageAlt="krullen"
          strength={300}
        >
          <div className="overmij text-center" style={{ height: '400' }} >
            <img className="png-size" src={mood} height="300" alt="logo" /> </div>
        </Parallax>
        <Container id='overmij' className="text-center pb-5">

          <Row lg="1" className="text-center mb-5" >

            <Col className="">
              <h1 className="p-5">Over Mij</h1>
              <p className="text-center">Ramazan A. is de oprichter van Coiffeur Soirée en een echte Curl Specialist. Hij is
                een alom bekende veteraan in de haarindustrie en is ondertussen een vaste schakel
                geworden. Zijn liefde en interesse voor krullend haar dateert al van in zijn beginjaren,
                omdat hij altijd al omringd werd door curlies in zijn entourage. Tijdens zijn opleiding
                haartooi merkte hij op dat er voor krullend haar een totaal andere aanpak nodig was.
                Door de jaren heen heeft hij de juiste technieken ontdekt, leren werken met de beste
                producten en geleerd wat het best werkt om krullen te knippen en te verzorgen.</p>
              <p>De naam &quot;Curl Specialist&quot; is trouwens niet zomaar ontstaan. Ramazan heeft een
                echte passie voor krullen. Naast de verzorging en het knippen van je krullen, geeft hij
                je ook gepersonaliseerd advies, biedt hij workshops aan en zoekt hij samen met jou
                naar de perfecte routine voor je haar. </p>
            </Col>
          </Row >

        </Container>
      </Container>
    </>
  );
}

export default OverMij;
